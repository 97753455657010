.aboutus-container {
    height: 100vh; 
    overflow: hidden; 
  }
  
  .aboutus-grid {
    height: 100%; 
  }
  
  .carousel-container {
    height: 100%; 
  }
  
  .carousel-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .slide-item {
    width: 100% !important; 
  }
  
  .carousel-image {
    width: 100%;
    height: 85vh;
    object-fit: cover;
  }
  
  @media (max-width: 600px) {
    .carousel-image {
      height: 300px; 
    }
  }
  