
/* CSS for responsive layout */
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-item {
  flex: 0 0 calc(50% - 10px); /* Adjust the width to fit two items per row with a gap of 20px */
  margin-bottom: 20px;
}

@media (max-width: 599px) {
  .product-item {
    flex: 0 0 calc(33.33% - 10px); /* Adjust the width to fit three items per row with a gap of 10px on smaller screens */
  }
}

button{
    font-size: 20px;
    border-width: 0px;
    margin: 20px;
 }

button:hover{
    background-color: rgb(16, 156, 16) ;
    border-width: 2px;
    color: white !important;
}

.cart-container {
    position: relative;
    transition: right 0.3s ease;
  }
  
  .cart-container.open {
    position: fixed;
    top: 100px;
    right: 20px;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    max-height: 50vh;
    overflow-y: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
 
  .product-image {
    width: 140px;
    height: 140px;
  }
  
  .product-button {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    background-color: grey;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .color-buttons, .structure-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .color-button, .structure-button {
    color: white;
    font-size: 20px;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .details-table {
    margin-top: 20px;
  }
  
  .cart-section {
    position: relative;
  }
  @media (max-width: 768px) {
    .MuiTable-root {
      width: 100%;
      overflow-x: auto;
    }
  }
    .MuiTableCell-body {
      font-size: 14px;
      padding: 8px; /* Adjust padding to ensure cell content is not cut off */
    }
    
    .MuiTableCell-root {
      padding: 8px; /* Adjust padding to ensure cell content is not cut off */
    }
  
  